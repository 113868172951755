/*Variables*/
$main-color: #ff54c8;
$gradient-right: linear-gradient(to right, #0E74F4 0%,#00D5E2 100%);
$gradient-right-trans: linear-gradient(to right, #0E74F4 0%,rgba(0, 213, 226, 0) 100%);
$gradient-bottom: linear-gradient(to bottom, #0E74F4 0%,#00D5E2 100%);
$gradient-bottom-opacity: linear-gradient(to bottom, rgba(14, 116, 244, 0.5) 0%, rgba(0, 213, 226, 0.5) 100%);

/*Fonts*/

/*General*/
body{
	h1, h2, h3, h4, h5, h6{
		font-family: 'Montserrat', sans-serif !important;
		line-height: 60px;
	}

h2{
	font-size: 46px;
}
	p, a{
		font-family: 'Open Sans', sans-serif !important;
	}
}

a:hover{
	text-decoration: none !important;
}

svg{
	stroke: $main-color;
	fill: $main-color;
	height: 100px;
}

span{
	&.p-red{
    padding-right: 10px;
    padding-left: 10px;
    background-color: $main-color;
    margin-right: 5px;
    border-radius: 5px;
    font-family: 'Verdana', cursive;
		font-weight: 600;
    color: #fff !important;
	}
}

input, textarea {
	width: 90%;
	display: inline-block;
	outline: none;
	background: none;
	color: #fff !important;
	box-sizing: border-box;
	border-radius: 4px !important;
	border: none !important;
	font-weight: 300;
	font-size: 14px;
	margin-right: 3%;
	padding: 10px;
	background: rgba(0, 0, 0, 0.3) !important;
	margin-bottom: 10px;
	transition: 0.3s !important;
	&:hover, &:focus{
		box-shadow: none !important;
		background: rgba(224, 34, 23, 0.5) !important;
	}
	&::placeholder{
		color: #fff;
	}
}

.logo {
	margin-right: 10px;
	max-height: 50px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.6);
}
/*Section*/
#home{
	background-image: url(../img/head-bg.jpg);
	background-size: cover;
	position: relative;
	min-height: 100vh;
	.container{
		min-height: 100vh !important;
		color: #fff;
		text-align: center;
		.title{
			font-weight: 800;
		}
		p{
			font-size: 18px;
		}
	}
	.bg{
		position: relative;
		height: 100%;
		z-index: 0;
		img{
			position: absolute;
			height: 100vh;
			right: 0;
			border-radius: 50vh 0 0 50vh;
			border-width: 0 0 0 30px;
			border-style: solid;
			border-color: $main-color;
			z-index:1;
		}
	}
}


#services{
	padding-top: 10vh;
	min-height: 50vh;
	background: #f2f2f2;
	.service{
		text-align: center;
border-radius: 5px;
position: relative;
padding: 50px 25px;
margin-bottom: 30px;
border: 1px solid rgba(238, 238, 238, 0.65);
box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.08);
transition: 0.36s;
background-color: #fff;
&:before {
    position: absolute;
    content: "";
    height: 25%;
    width: 25%;
    left: 10px;
    top: 10px;
    border-top-left-radius: 5px;
    border-top: 1px solid #c2bc35;
    border-left: 1px solid #c2bc35;
}
&:after {
    position: absolute;
    content: "";
    height: 25%;
    width: 25%;
    right: 10px;
    bottom: 10px;
    border-bottom-right-radius: 5px;
    border-bottom: 1px solid #c2bc35;
    border-right: 1px solid #c2bc35;
}
	}
}

#about{
	background-color: #fff;
	min-height: 50vh;
	img{
		display: block;
		position: static;
		z-index: 1 !important;
		    box-shadow: 0 0 10px rgba(6,6,6,.1);
				border-radius: 5px;
	}
	h2{
		display: block;
	}
}

#contact{
	background-image: url(../img/contact.jpg);
	background-size: cover;
	background-attachment: fixed;
	min-height: 50vh;
position: relative;
color: #fff;
.button{
	border: none;
	float: left;
	margin-bottom: 15px;
	font-family: inherit !important;
	outline: none;
}
li{
	list-style-type: none;
	text-align: left;
}
.info-box-wrapper {
    width: 90%;
    display: inline-block;
    outline: none;
    background: none;
    color: #f1f1f1;
    box-sizing: border-box;
    border-radius: 4px;
    border: none;
    font-weight: 300;
    font-size: 14px;
    margin-right: 3%;
    padding: 10px;
    background: rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    padding-left: 60px;
	    color: #f1f1f1;
	    font-weight: 300;
	    font-size: 14px;
		&:hover{
			background-color: $main-color;
		}
		.icon-box{
			position: absolute;
			background-color: $main-color;
left: 0;
top: 0;
font-size: 16px;
width: 50px;
height: 100%;
		svg{
			height: 30px;
			fill: #fff;
			stroke: #fff;
		}
	}
}
a, a:hover{
	color: #fff;

}
}

footer{
	background: #000;
	color: #fff;
	span{
		line-height: 70px;
		font-size: 20px;
		color: $main-color;
	}
	a{
		color: #fff;
	}
	hr{
		background-color: #F5F5F5;
		width: 60%;
		height: 1px;
	}

	svg{
		height: 50px;
		margin-bottom: 10px;
		stroke: $main-color;
		fill: $main-color;
	}

	#copyright{
		margin: 0;
		text-align: center;
		padding-bottom: 50px;
		padding-top: 20px;
	}
}
/*Navbar*/
.navbar {
	position: fixed;
	z-index: 10000 !important;
	.navbar-brand{
		color: #FFF;
font-weight: bold;
font-size: 2rem;
line-height: 35px;
	}

	.nav-link{
		font-size: 16px;
		font-weight: 600;
		color: #fff !important;
	}

	.nav-link:hover{
		color: $main-color !important;
	}

}
.navbar.menu{
	background-color: #000 !important;
}

button.navbar-toggler{
	float: right;
}

/*Buttons*/
.button{
	text-decoration: none;
	outline: none;
	display: inline-block;
	color: white;
	border-radius: 6px;
	z-index: 10;
	padding: 0 20px;
	white-space: nowrap;
	font-size: 15px;
	line-height: 40px;
	font-weight: 500;
	color: rgb(255, 255, 255);
	font-family: Roboto;
	text-transform: uppercase;
	background:$main-color;
	background-size: 200% auto;
	transition: .5s;
	&:hover {
		background: #fff;
		color: #000;
	}
	}



	@media screen and (max-width:878px){
		.navbar.navbar-expand-md{
			background-color: #000;
		}
		.navbar-brand{
			font-size: 1.3rem !important;
		}
		#home{
			background-image: url(../img/head-bg.jpg);
			background-size: cover;
			padding-top: 4rem !important;
			.title{
				font-size: 2.3rem;
				line-height: 2.5rem;
			}
					.button{
						margin-bottom: 10px;
					}
		#about{
		.img-border{
			img{
				top: 2rem;
			}
				padding: 0 0 0 2rem;
				margin-bottom: 5rem;
			}
		}

		#contact{
			margin-top: 5rem;
			.address, input, textarea{
			text-align: center;
		}
	}

	}
}
.form-group {
	&.error{
		input, textarea {
		border: 2px solid red !important;	
		}
		
	}
}